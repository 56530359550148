<template>
  <el-dialog
    :title="$t('openAdditAcc.selectMt4Tips.title')"
    :visible.sync="value"
    append-to-body
    width="600px"
    custom-class="selectMt4TipDialog"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center>
      <span>{{$t('openAdditAcc.selectMt4Tips.tipText')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleChange('mt5')">{{$t('openAdditAcc.selectMt4Tips.switchBtn')}}</el-button>
        <el-button type="text" @click="handleChange('mt4')">{{$t('openAdditAcc.selectMt4Tips.continueBtn')}}</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'SelectMt4Tips',
  props: { value: Boolean },
  data() {
    return {

    }
  },
  methods: {
    handleChange(val) {
      if (val === 'mt5') {
        this.$emit('onOk', val)
      }
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss">
  .selectMt4TipDialog {
    padding: 40px 70px;
    .el-dialog__header {
      margin-bottom: 0;
    }
    .el-dialog__title {
      font-family: Outfit;
      font-size: 26px;
      font-weight: 600;
      line-height: 33.8px;
      letter-spacing: 0.01em;
      text-align: center;
    }
    .el-dialog__body {
      font-family: Outfit;
      font-size: 18px;
      font-weight: 400;
      padding: 50px 50px 45px;
      margin: -25px -50px -15px;
      line-height: 25.2px;
      text-align: center;
      color: #1D1D1F;
    }
    .el-dialog__footer {
      flex-direction: column;
      display: flex;
      margin-top: 10px;
      padding: 0;
      button {
        width: 329px;
        max-width: 100%;
        background: #006BFF;
      }
      .el-button + .el-button {
        margin-left: 0px;
        margin-top: 10px;
        border: 0;
        color: #006BFF;
        background: transparent;
      }
    }
  }
</style>
